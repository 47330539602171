<template>
    <v-row no-gutters class="ma-0 px-2">
        <v-col cols="12" align="center">
            <v-row no-gutters class="ma-0">
                <v-col cols="12">
                    <HorizontalCircularProgress color="primary" size="100" :value="aprob_cursos.porcentaje * 100">
                        <div>
                            <span class="d-block subtitle-2 font-weight-bold">Curso</span>
                            <span class="d-block title font-weight-regular" :title="aprob_cursos.nombre">{{ aprob_cursos.nombre | see_more(30) }}</span>
                            <span class="d-block subtitle-2">{{ aprob_cursos.cantidad }} cursos aprobados del total general</span>
                        </div>
                    </HorizontalCircularProgress>
                </v-col>
                <v-col cols="12">
                    <HorizontalCircularProgress color="green" size="100" :value="aprob_empresas.porcentaje * 100">
                        <div>
                            <span class="d-block subtitle-2 font-weight-bold">Empresa</span>
                            <span class="d-block title font-weight-regular" :title="aprob_empresas.nombre">{{ aprob_empresas.nombre | see_more(30) }}</span>
                            <span class="d-block subtitle-2">{{ aprob_empresas.cantidad }} cursos aprobados del total general</span>
                        </div>
                    </HorizontalCircularProgress>
                </v-col>
                <v-col cols="12">
                    <HorizontalCircularProgress color="secondary" size="100" :value="aprob_gerencias.porcentaje * 100">
                        <div>
                            <span class="d-block subtitle-2 font-weight-bold">Gerencia</span>
                            <span class="d-block title font-weight-regular" :title="aprob_gerencias.nombre">{{ aprob_gerencias.nombre | see_more(30) }}</span>
                            <span class="d-block subtitle-2">{{ aprob_gerencias.cantidad }} cursos aprobados del total general</span>
                        </div>
                    </HorizontalCircularProgress>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import HorizontalCircularProgress from '@/shared/components/HorizontalCircularProgress'

export default {
    props: ['value'],
    components: {
        HorizontalCircularProgress
    },
    computed: {
        aprob_cursos() {
            if(!this.value || !this.value.aprob_cursos) return { nombre: "", cantidad: 0, porcentaje: 0 };
            return this.value.aprob_cursos;
        },
        aprob_empresas() {
            if(!this.value || !this.value.aprob_empresas) return { nombre: "", cantidad: 0, porcentaje: 0 };
            return this.value.aprob_empresas;
        },
        aprob_gerencias() {
            if(!this.value || !this.value.aprob_gerencias) return { nombre: "", cantidad: 0, porcentaje: 0 };
            return this.value.aprob_gerencias;
        }
    }
}
</script>