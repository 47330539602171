<template>
    <v-data-table :items="datos" :headers="headers" dense>
        <template v-slot:item.nombre="{ item }">
            <v-icon color="indigo" left>group</v-icon>{{ item.nombre | see_more(40) }}
        </template>
        <template v-slot:item.evaluador="{ item }">
            {{ item.evaluador }}
        </template>
        <template v-slot:item.cursos_rel="{ item }">
            {{ item.cursos_completados }} de {{ item.cursos_count }} ({{ item.cursos_rel | decimal_percent }})
        </template>
        <template v-slot:item.fecha_inicio="{ item }">
            {{ item.fecha_inicio | moment("L") }}
        </template>
        <template v-slot:item.fecha_termino="{ item }">
            {{ item.fecha_termino | moment("L") }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn icon :to="{ name: 'dashboard_cap_item', params: { id: item.id } }" depressed class="subtitle-2">
                <v-icon>chevron_right</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: ['value', 'finalizados'],
    computed: {
        headers() {
            var heads = [
                { value: "nombre", text: "Grupo" },
                { value: "evaluador", text: "Evaluador" }
            ]

            if(!!this.finalizados) {
                heads.push({ value: "cursos_rel", text: "Progreso" });
            }

            heads.push({ value: "fecha_inicio", text: "Inicio" });
            heads.push({ value: "fecha_termino", text: "Termino" });
            return heads;
        },
        datos() {
            return this.value || [];
        }
    }
}
</script>