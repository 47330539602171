<template>
    <v-data-table dense :items="items" :headers="headers" :hide-default-footer="items.length < 10" :items-per-page="items.length < 10 ? -1 : 10">
        <template v-slot:no-data>
            <EmptyCard class="my-4 py-6" text="No hay cursos caducados" icon="check" />
        </template>
        <template v-slot:item.nombre="{ item }">
            <v-icon color="red" left>error</v-icon>{{ item.nombre }}
        </template>
        <template v-slot:item.validez_curso="{ item }">
            {{ item.validez_curso | moment("L") }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn depressed dark color="secondary" class="subtitle-2" :to="{ name: 'dashboard_cap_item', params: { id: item.id }}"><v-icon left>chevron_right</v-icon> Ir</v-btn>
        </template>
    </v-data-table>
</template>

<script>
import EmptyCard from '@/shared/components/EmptyCard'

export default {
    props: ['value'],
    components: {
        EmptyCard
    },
    computed: {
        items() {
            if(!this.value) return [];
            return this.value;
        }
    },
    data: () => ({
        headers: [
            { value: "nombre", text: "Nombre", sortable: false },
            { value: "validez_curso", text: "Valido hasta", sortable: false, divider: true, width: '120px' },
            { value: "actions", text: "", sortable: false, width: '32px' }
        ]
    })
}
</script>