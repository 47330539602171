<template>
    <div>
        <div>
            <v-data-iterator :items="items" :page="page" :items-per-page.sync="item_per_page" hide-default-footer>
                <template v-slot:no-data>
                    <EmptyCard class="ma-4" v-if="items.length < 1 || !items" text="Sin datos" icon="priority_high" />
                </template>
                <template v-slot:item="{ item }">
                    <v-list-item>
                        <v-list-item-icon class="mr-2"> 
                            <span class="display-1 font-weight-light">{{ items.indexOf(item) + 1 }}º</span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.nombre }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.cantidad }} aprobados.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:footer>
                    <v-pagination v-model="page" :length="page_count" />
                </template>
            </v-data-iterator>
        </div>
    </div>
</template>

<script>
import EmptyCard from '@/shared/components/EmptyCard'

export default {
    props: ['items'],
    components: {
        EmptyCard
    },
    computed: {
        page_count() {
            return Math.max(1, Math.round(this.items.length / this.item_per_page));
        }
    },
    data: () => ({
        page: 1,
        item_per_page: 5
    })
}
</script>