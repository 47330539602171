<template>
    <div>
        <v-row class="ma-0">
            <v-col cols="12" sm="8">
                <v-label>Curso</v-label>
                <v-select v-model="curso" clearable solo outlined dense flat placeholder="Seleccione un curso..." hide-details prepend-inner-icon="folder" :items="items" item-text="nombre" item-value="id" @change="update_curso"/>
            </v-col>
            <v-col cols="12" sm="4">
                <v-radio-group hide-details class="pa-0 ma-0" v-model="show_mode">
                    <v-radio :value="0" label="Ver Total" />
                    <v-radio :value="1" label="Por Gerencias" />
                    <v-radio :value="2" label="Por Empresas" />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider />
        <div class="pa-0">
            <v-alert class="ma-2" type="error" v-if="error.visible">{{ error.text }}</v-alert>
            <SimpleXYChart type="area" xtype="numeric" :series="current" :extra="config" />
        </div>
        <v-divider />
        <v-row class="ma-0">
            <v-col cols="12" sm="4">
                <v-radio-group hide-details class="pa-0 ma-0" v-model="use_time_range" @change="update_everything">
                    <v-radio :value="false" label="Historico" />
                    <v-radio :value="true" label="Rango específico" />
                </v-radio-group>
            </v-col>
            <v-col cols="12" sm="4">
                <v-label>Inicio</v-label>
                <DatePickerText v-model="range.inicio" dense flat :disabled="!use_time_range" @input="update_everything" />
            </v-col>
            <v-col cols="12" sm="4">
                <v-label>Termino</v-label>
                <DatePickerText v-model="range.termino" dense flat :disabled="!use_time_range" @input="update_everything" />
            </v-col>
        </v-row>
        <v-divider />
    </div>
</template>

<script>
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import DatePickerText from '@/shared/components/DatePickerText'
import moment from 'moment'
import { axiosHelper, messages } from '@/helpers'

export default {
    props: ['items', 'value'],
    components: {
        DatePickerText,
        SimpleXYChart
    },
    mounted() {
        this.$forceUpdate();
    },
    computed: {
        current() {
            if(!this.series) return [];

            var current_series = [];

            switch(this.show_mode) {
                case 0: current_series = [this.series.main]; break;
                case 1: current_series = [...this.series.gerencias]; break;
                case 2: current_series = [...this.series.empresas]; break;
            }

            return current_series;
        }
    },
    data: () => ({
        show_mode: 0,
        use_time_range: false,
        series: null,
        curso: null,
        range: {
            inicio: null, 
            termino: null 
        },
        error: {
            visible: false,
            text: ""
        },
        config: {
            xaxis: {
                type: 'datetime',
                labels: {
                    formatter: (val) => moment(val).add(1, 'day').format("L")
                }
            },
            yaxis: {
                type: 'numeric',
                labels: {
                    formatter: (val) => Math.round(val)
                }
            }
        }
    }),
    methods: {
        update_everything() {
            this.update_curso(this.curso);
        },
        update_curso(curso) {
            this.error.visible = false;
            var data = null;
            var payload = { curso: curso };

            if(this.use_time_range) {
                payload.inicio = this.range.inicio;
                payload.termino = this.range.termino;
            }

            axiosHelper.post('edu/dashboard/', payload)
                .then(response => {
                    this.series = response.data.series;
                    data = response.data;
                    this.range.inicio = response.data.time_range.inicio;
                    this.range.termino = response.data.time_range.termino;
                    this.$forceUpdate();
                })
                .catch(error => {
                    this.series = null;
                    this.error.visible = true;
                    this.error.text = error.response.data.detail || messages.texts['error_desconocido'];
                })
                .then(() => {
                    this.curso = curso;
                    this.$emit('change', data);
                    this.$emit('series', this.series);
                });
            
        }
    }
}
</script>